import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  DataGrid,
  nbNO,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Box } from "@mui/material";

const columns = [
  {
    field: "lastName",
    headerName: "Etternavn",
    width: 150,
    editable: false,
    sortable: true,
  },
  {
    field: "firstName",
    headerName: "Fornavn",
    width: 150,
    editable: false,
    sortable: true,
  },
  {
    field: "department",
    headerName: "Avdeling",
    width: 150,
    sortable: true,
    editable: false,
  },
  {
    field: "phone",
    headerName: "Telefon",
    width: 150,
    editable: false,
    sortable: true,
  },
  {
    field: "email",
    headerName: "Email",
    width: 400,
    sortable: true,
    editable: false,
  },
];

export default function CustomDataGrid({ rows }) {
  const navigate = useNavigate();
  const csvOptions = { delimiter: ";", utf8WithBom: true };
  const [pageSize, setPageSize] = useState(15);
  const role = JSON.parse(localStorage.getItem("roles"));
  const isIntegrasjonAdmin = role.includes("org.admin.integration");

  const CustomToolbar = (props) => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={csvOptions} />
    </GridToolbarContainer>
  );

  // console.log(rows);

  return (
    <Box
      sx={{
        mt: 3,
        height: 600,
        maxHeight: 800,
        width: "90%",
        maxWidth: "60rem",
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#2F4874",
          color: "#fff",
          fontSize: 16,
        },
      }}
    >
      <DataGrid
        sx={{
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              ":hover": {
                color: "#5177b8",
              },
              cursor: "pointer",
              "&:nth-of-type(2n)": {
                backgroundColor: "rgba(234, 234, 234, .7)",
              },
              "& .MuiDataGrid-cell:active": {
                outline: "none",
              },
            },
          },
          "& .MuiDataGrid-toolbarContainer ": {
            backgroundColor: "#2F4874",
            borderBottom: "1px solid #fff",
            p: 1,
            zIndex: "modal",
            "& .MuiButtonBase-root": {
              color: "#fff",
              paddingRight: 4,
            },
          },
          "& .MuiDataGrid-columnHeaders": {
            borderRadius: 0,
          },
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[15, 50, 75]}
        rows={rows}
        columns={columns}
        autoHeight
        pagination
        disableColumnMenu
        disableSelectionOnClick
        rowHeight={50}
        components={{ Toolbar: CustomToolbar }}
        onRowClick={(row) => {
          if (!isIntegrasjonAdmin) {
            const id = row.id;
            navigate(`/resultat/${id}`);
          }
        }}
        localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
      />
    </Box>
  );
}
