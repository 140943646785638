import getCookie from "../../utils/cookieUtils";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  GetAllDepartments,
} from "../../api/endpoints";

export const getDepChangesApi = createApi({
  reducerPath: 'getDepChanges',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const token = getCookie('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getAllDepartments: builder.query({
      query: () => ({ url: GetAllDepartments, method: 'GET' }),
      skipCache: true
    })
  })
});

export const {
  useGetAllDepartmentsQuery,
} = getDepChangesApi;
