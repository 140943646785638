import { useState, useEffect, useCallback } from "react";
import React from "react";
import {
  TableHead,
  Table,
  TableBody,
  TableRow,
  Paper,
  TableContainer,
  TableCell,
  Popover,
  TableSortLabel,
  IconButton,
  TablePagination,
  Typography,
} from "@mui/material";
import { GetAllAdmins, DeleteAdmin } from "../../api/endpoints";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { successNote, toastErrorNotify } from "../../helpers/toastNotify";
// import { FormatListNumbered } from "@mui/icons-material";
import DeleteButtonWithConfirmation from "../../layout/DeleteButtonWithConfirmation";
import apiClient from "../../api/apiClient";
import CenteredLoading from "../../layout/CenteredLoading";

const AdminList = () => {
  const [adminData, setAdminData] = useState(null);
  const [adminId, setAdminId] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = adminData?.sort((a, b) => {
    const isAsc = order === "asc";
    if (orderBy === "name") {
      return isAsc
        ? a?.name?.localeCompare(b.name)
        : b?.name?.localeCompare(a.name);
    }
  });

  // ! Get Admin User List
  const getAdminUser = useCallback(async () => {
    try {
      const adminUsers = await apiClient.get(GetAllAdmins);
      setAdminData(adminUsers.data.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [adminData]);

  const paginatedData = adminData?.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );
  // console.log(adminData);

  // ! Delete Admin User

  async function deleteAdmin() {
    try {
      const params = {
        adminId,
      };
      await apiClient.post(DeleteAdmin, params);
      successNote("Admin-bruker slettet");
      handleCloseMenu();
      getAdminUser();
    } catch (err) {
      if (!err.response) {
        toastErrorNotify("Ingen svar fra serveren");
      } else if (err.response.data?.data) {
        toastErrorNotify(err.response.data.data?.validationErrors[0]);
      } else if (err.response.data.data === null) {
        toastErrorNotify(err.response.data?.message);
      } else {
        toastErrorNotify("Mislyktes");
      }
    }
  }

  useEffect(() => {
    getAdminUser();
  }, []);

  function getCustomRoleName(roleName) {
    if (roleName === "org.admin") {
      return "Super admin";
    }
  
    // Diğer roleName'ler için kontrol
    switch (roleName) {
      case "org.admin.department":
        return "Enhets-administrator";
      case "org.admin.course":
        return "Avdelings-administrator";
      case "org.admin.integration":
        return "Integrasjons-administrator";
      case "org.admin.assistant":
        return "System-administrator";
      default:
        return "Super-administrator";
    }
  }
  
  
  

  // ! Handle 3 Dots Menu in the List

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  return (
    <>
      {loading ? (
        <CenteredLoading tekst={"Laster inn…"} />
      ) : adminData?.length < 1 ? (
        <Typography sx={{ color: "warning.main" }}>
          Det finnes ingen administratorbrukere ennå
        </Typography>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ maxWidth: 1000 }}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead className="table-header">
                <TableRow>
                  <TableCell sx={{ color: "#ffffff" }}>Roller</TableCell>
                  <TableCell sx={{ color: "#ffffff", paddingRight: 2 }}>
                    <TableSortLabel
                      active={orderBy === "name"}
                      direction={orderBy === "name" ? order : "asc"}
                      onClick={(event) => handleSortRequest(event, "name")}
                      sx={{
                        "&.MuiButtonBase-root.MuiTableSortLabel-root": {
                          color: "#fff",
                        },
                        "&.MuiButtonBase-root.MuiTableSortLabel-root:hover": {
                          color: "#fff",
                        },
                        "& .MuiTableSortLabel-icon": {
                          color: "#fff !important",
                        },
                      }}
                    >
                      Navn
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#ffffff", paddingRight: 2 }}>
                    E-postadresse
                  </TableCell>
                  <TableCell sx={{ color: "#ffffff", paddingRight: 2 }}>
                    Mobilnummer
                  </TableCell>
                  <TableCell sx={{ color: "#ffffff" }}>
                    Opprettelsesdato
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/*// ! Mapping the admin users list */}

                {paginatedData?.map((data, index) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:nth-of-type(even)": {
                        backgroundColor: "#f4f5f5",
                      },
                    }}
                    key={index}
                  >
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {data?.roles &&
                        getCustomRoleName(data?.roles[1]?.roleName)}
                    </TableCell>
                    <TableCell>
                      {data.name === null && data.surname === null
                        ? "Denne admin-brukeren har ikke blitt registrert"
                        : `${data.name} ${data.surname}`}
                    </TableCell>
                    <TableCell>{data.email}</TableCell>
                    <TableCell>{data.phone}</TableCell>
                    <TableCell>
                      {
                        new Date(data.creationDateTime)
                          .toLocaleString()
                          .split(",")[0]
                      }
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="large"
                        color="inherit"
                        onClick={(e) => {
                          handleOpenMenu(e);
                          setAdminId(data.adminId);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 15, 25]}
              component="div"
              count={adminData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              labelRowsPerPage="Rader pr. side"
            />
          </TableContainer>

          {/* // ! Popover Functions */}
          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                "& .MuiMenuItem-root": {
                  px: 1,
                  typography: "body2",
                  borderRadius: 0.75,
                },
              },
            }}
          >
            {/* <MenuItem>
        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
        Update
      </MenuItem> */}
            <DeleteButtonWithConfirmation
              onDeleteConfirm={deleteAdmin}
              color="#f44336"
              icon={<DeleteOutlineIcon />}
              buttonText={<>Slett</>}
            />
          </Popover>
        </>
      )}
    </>
  );
};

export default AdminList;
