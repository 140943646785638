//  const apiUrl = 'https://gizemtest.azurewebsites.net';
// const apiUrl = 'https://api.dev.makingview.com';
//const apiUrl = 'https://localhost:7156';
const apiUrl = process.env.REACT_APP_BASE_URL;

export const OtpUrl = apiUrl + '/api/OrgAdminUserAuth/SendSMS';
export const LoginUrl = apiUrl + '/api/OrgAdminUserAuth/Login';
export const UploadCSV = apiUrl + '/api/Integrations/UploadCSV';
export const UploadExcel = apiUrl + '/api/Integrations/UploadExcel';

export const ForgotPasswordUrl = apiUrl + '/api/OrgAdminUserAuth/ForgotPassword?email=';
export const PasswordResetUrl = apiUrl + '/api/OrgAdminUserAuth/ChangePassword';
export const GetRolesUrl = apiUrl + '/api/BaseUsers/GetAdminDetailsByToken';
export const GetUserDetail = apiUrl + '/api/BaseUsers/GetAdminDetailsByToken';
export const GetAllFundamentalRoles = apiUrl + '/api/Roles/GetFundamentalUserRoles';
export const GetAllAdmins = apiUrl + '/api/AdminUsers/GetAllAdmins';
export const showLicense = apiUrl + '/api/Licenses/ShowLicense';
export const GetAllCourses = apiUrl + '/api/Courses/GetAll';
export const GetAllUsers = apiUrl + '/api/EndUsers/GetAllUsersByDepartmentGroups';
export const AddAdmin = apiUrl + '/api/OrgAdminUserAuth/AddAdmin';
export const GetAdminDetails = apiUrl + '/api/Admin/GetAdminDetail';
export const DeleteAdmin = apiUrl + '/api/OrgAdminUserAuth/DeleteAdmin';
export const AddTemplateFysisk = apiUrl + '/api/TemplateFysisk/Add';
export const AddTemplateMental = apiUrl + '/api/TemplateMental/Add';
export const AddTemplateForstehjelp = apiUrl + '/api/TemplateFirstAid/Add';
export const GetMentalTemplates = apiUrl + '/api/TemplateMental/GetAll';
export const GetFysiskTemplates = apiUrl + '/api/TemplateFysisk/GetAll';
export const GetForstehjelpTemplates = apiUrl + '/api/TemplateFirstAid/GetAll';
export const DeleteMentalTemplate = apiUrl + '/api/TemplateMental/Delete';
export const DeleteFysiskTemplate = apiUrl + '/api/TemplateFysisk/Delete';
export const DeleteForstehjelpTemplate = apiUrl + '/api/TemplateFirstAid/Delete';
export const AddCourses = apiUrl + '/api/Courses/Add';
export const DeleteCourses = apiUrl + '/api/Courses/Delete';
export const GetAllModules = apiUrl + '/api/OrganizationsModules/GetAllModulesBelongedToOrganization';
export const GetAllSchedulesByUpperAdmin = apiUrl + '/api/Schedules/GetAllSchedulesFromAllDepartments';
export const GetAllSchedulesByDepartmentAdmin = apiUrl + '/api/Schedules/GetAllSchedulesFromADepartment';
export const DeleteScheduleCourseByUpperAdmin = apiUrl + '/api/Schedules/CancelAllSchedulesOfADepartmentByCourseIdAsUpperAdmin';
export const DeleteScheduleCourseByDepartmentAdmin = apiUrl + '/api/Schedules/CancelAllSchedulesOfADepartmentAsDepartmentAdmin';
export const GetDepartmentUsersByDepartmenAdmin = apiUrl + '/api/EndUsers/GetAllUsersOfADepartmentByToken';
export const GetAllDepartments = apiUrl + '/api/EndUsers/GetAllUsersByDepartmentGroups';
export const GetDepartments = apiUrl + '/api/Departments/GetAllDepartments';
export const ContactUs = apiUrl + '/api/Contacts/RegisterMail';
export const GetCustomText = apiUrl + '/api/Schedules/GetCustomTexts';
export const AddScheduleByUpperAdmin = apiUrl + '/api/Schedules/AddByUpperAdmin';
export const AddScheduleByDepartmentAdmin = apiUrl + '/api/Schedules/AddByDepartmentAdmin';
export const RegisterAdmin = apiUrl + '/api/OrgAdminUserAuth/RegisterAdmin';
export const RegisterRootAdmin = apiUrl + '/api/OrgAdminUserAuth/RegisterRootAdmin';
export const GetUserResult = apiUrl + '/api/Results/GetAllResultsByUserId';
export const GetCourseDetails = apiUrl + '/api/Courses/GetDetail';
export const DeleteScheduleCourseAsUpperadmin = apiUrl + '/api/Schedules/CancelAllSchedulesByGroupId';
export const GetMentalTemplateDetails = apiUrl + '/api/TemplateMental/Get';
export const GetFysiskTemplateDetails =apiUrl + '/api/TemplateFysisk/Get';
export const CheckAvailableTemplates = apiUrl + '/api/Courses/CheckAvailableTemplates';
export const GeneralResultAsUpperAdmin = apiUrl + '/api/Results/GetGroupResultsByUpperAdmin';
export const GetResultAsDepartmentAdmin = apiUrl + '/api/Results/GetGroupResultsByDepartmentAdmin';
export const GetCourseOwners = apiUrl + '/api/AdminUsers/GetCourseOwners';
export const GetAllSchedulesFromTheDepartment = apiUrl + '/api/Schedules/GetAllSchedulesFromTheDepartment'
export const CheckDepartments = apiUrl + '/api/Departments/CheckIfThereIsAnyChanges'
export const GetUsersInConflict = apiUrl + '/api/Departments/GetUsersInConflict'
export const SetUpDepartments = apiUrl + '/api/Departments/SetupDepartmentAndUsers'
export const ResetDepartments = apiUrl + '/api/Departments/ResetDepartmentsAndUsers'
export const FixUserConflict = apiUrl + '/api/Departments/FixUserConflict'
export const FixUserConflictsBulk = apiUrl + '/api/Departments/FixUserConflictsBulk'
export const CoursesWithModules = apiUrl + '/api/Courses/GetAllWithModules'
export const DepartmentUsersByToken = apiUrl + '/api/EndUsers/GetAllUsersOfADepartmentByToken'
export const GetCourseResults = apiUrl + '/api/Results/GetGroupResultsByGroupId'
export const GetModulesBelongedToOrganization = apiUrl + '/api/OrganizationsModules/GetAllModulesBelongedToOrganization'
//! For registeration of new admin
export const SendPhoneValidationOTP = apiUrl + '/api/OrgAdminUserAuth/SendPhoneValidationOTP'
export const ValidateOTP = apiUrl + '/api/OrgAdminUserAuth/ValidatePhoneNumber'
export const AcceptUserAgreement = apiUrl + '/api/OrgAdminUserAuth/AcceptUserAgreement'

export const DashboradResultAsUpperAdmin = apiUrl + '/api/Results/GetGeneralResultAsUpperAdmin'
export const DashboardResultAsDepAdmin = apiUrl + '/api/Results/GetGeneralResultAsDepartmentAdmin'