import { React, useState, useEffect } from "react";
import {
  Checkbox,
  TextField,
  Autocomplete,
  styled, FormHelperText,
  lighten, Grid, FormControl
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { GetAllUsers } from "../../../api/endpoints";
import apiClient from "../../../api/apiClient";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function UserSelect({handleUserSelect, formSubmit, reset}) {
  const [userData, setUserData] = useState([]);
  const [selectedBox, setSelectedBox]= useState([])
  // const [formValid, setFormValid] = useState(false);

  // ! API call to get departments and workers

  useEffect(() => {
    (async () => {
      try {
        const departmentUsers = await apiClient.get(GetAllUsers);
        setUserData(departmentUsers.data.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);


   // ! Organise the JSON data

   const flattenedUsers = userData.reduce(
    (acc, { user }) => acc.concat(user),
    []
  );
  const options = flattenedUsers.map(({ name, surname, departmentId, userId}) => ({
    name: `${name} ${surname}`,
    departmentName: `${departmentId}`,
    id : `${userId}`
  }));


  // ! Handle Selected Departments and Users

  const checkOption = (option) =>{
    const check = selectedBox?.some((item)=>item.id === option.id);
    return check
  }

  const checkGroup =(group)=>{
    const groupLength = options.filter((d) => d.departmentName === group).length;
    const selectedGroupLength = selectedBox.filter(
      (d) => d.departmentName === group
    ).length;
    return groupLength === selectedGroupLength;
  };

  const selectGroup = (group)=>{
    const groupedUsers = options.filter((d) => d.departmentName === group);
    const selectedDepartments = selectedBox.filter(
      (d) => d.departmentName === group
    );
    if (selectedDepartments.length > 0) {
      setSelectedBox((prevState) => [
        ...prevState.filter((d) => d.departmentName !== group)
      ]);
    } else {
      setSelectedBox((prevState) => [...prevState, ...groupedUsers]);
    }
  }

  useEffect(() => {
    handleUserSelect(selectedBox)
    // setFormValid(selectedBox.length > 0);
  }, [selectedBox]);

  useEffect(() => {
    setSelectedBox([]);
  }, [reset]);


  // ! Style Group Header in Autocomplete

  const GroupHeader = styled("div")(({ theme }) => ({
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  }));

  return (
    <Grid item xs={12}>
  <FormControl variant="standard"  fullWidth required error={reset===false && formSubmit && selectedBox.length=== 0}>
    <Autocomplete
      multiple
      fullWidth
      disableCloseOnSelect
      value={selectedBox}
      onChange={(_, option)=>{setSelectedBox([...option]); handleUserSelect(selectedBox)}}
      options={options}
      groupBy={(option) => option.departmentName}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Velg avdeling"
          variant="standard"
          placeholder="Legg til avdeling"
          required
          error = {reset===false && formSubmit && selectedBox.length === 0}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={checkOption(option)}
          />
          {option.name}
        </li>
      )}
      renderGroup={(params, index) => (
        <div key={params.group + '-' + index}>
          <GroupHeader >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={checkGroup(params.group)}
              onClick={() =>selectGroup(params.group)}
            />
            {params.group}
          </GroupHeader>
          {/* kode for å vise individuelle ansatte */}
          {/* <> {params.children} </> */}
        </div>
      )}
    />
  {reset===false && formSubmit && selectedBox.length === 0 && <FormHelperText sx={{color:'red'}}>Dette feltet er påkrevd</FormHelperText>}
  </FormControl>
    </Grid>
  );
}
