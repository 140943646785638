import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import CourseDetailDisplay from "./CourseDetailDisplay";
import Loading from "../../../layout/Loading";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Button,
  Popper,
  MenuList,
  Grow,
  ClickAwayListener,
  Paper,
  Typography,
  Grid, Stack,
} from "@mui/material";
import { GetUserResult } from "../../../api/endpoints";
import { useParams, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DefinedRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { defaultStaticRanges } from "./no";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import apiClient from "../../../api/apiClient"

const EmployeeResultsContainer = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [userResult, setUserResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [filteredResults, setFilteredResults] = useState([]);
  const params = useMemo(() => ({
    userId: id,
  }), [id]);
  const userName = userResult && userResult.length > 0 ? [...new Set(userResult.map(course => course.userName))] : 'Bilinmeyen Kullanıcı';
  const courseName = userResult && userResult.length > 0 ? [...new Set(userResult.map(course => course.courseName))] : 'Kurs Bilgisi Yok';

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  function handleDateRangeChange(date) {
    setDateState([date?.selection]);
    const startDate = date.selection.startDate;
    const endDate = date.selection.endDate;

    let filtered = userResult.filter((item) => {
      const itemStartDate = new Date(item.startDateTime);
      const itemEndDate = new Date(item.endDateTime);
      return (
        (itemStartDate >= startDate && itemStartDate <= endDate) ||
        (itemEndDate >= startDate && itemEndDate <= endDate)
      );
    });
    setFilteredResults(filtered);
  }

  const memoizedGetUserResult = useCallback(() => {
    async function getUserResult() {
      try {
        const response = await apiClient.post(GetUserResult, params);
        const sortedData = response.data.data.sort((a, b) => {
          if (a.isActive && !b.isActive) return -1;
          if (!a.isActive && b.isActive) return 1;
          return a.courseId - b.courseId;
        });
        setUserResult(sortedData);
        setLoading(false);
        setFilteredResults(sortedData);
      } catch (err) {
        console.log(err);
      }
    }

    getUserResult();
  }, [setUserResult, setLoading, setFilteredResults, params]);

  useEffect(() => {
    memoizedGetUserResult();
  }, [memoizedGetUserResult]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const filteredDetails = filteredResults.length > 0 ? filteredResults.map((course, i) => {
      let totalScore = 0;
      let increments = 0;
    const courseModules = course.modules ? course.modules.map(module => {
      
      const submodules = {};
      for (const key of ['teori', 'test', 'training']) {
        if (module.submodules[key] && module.submodules[key].isScheduled) {
          submodules[key] = module.submodules[key];

          // Calculate the score for test and training only
          if (key !== 'teori' && module.submodules[key].bestScoreInHistory !== undefined) {
            totalScore += module.submodules[key].bestScoreInHistory;
            increments += 1;
          }
        }
      }
      return {
        ...module,
        submodules: submodules,
      };
    }) : [];
    const averageScore = increments > 0 ? totalScore / increments : 0;
  
    const updatedCourseData = {
      ...course,
      modules: courseModules,
      score: averageScore
    };

    return <CourseDetailDisplay key={i} courseData={updatedCourseData} />;
  }) : <Typography variant="body1">The user has no course</Typography>;

  return (
    <Grid sx={{ marginLeft: 2 }}>
      <Button
        sx={{ fontWeight: 600, marginTop: 3, marginLeft: 1, fontSize: 13,
        cursor: "pointer",
        color: "#0091ea", }}
        startIcon={<ArrowBackIcon />}
        onClick={handleGoBack}
      >
        Gå tilbake
      </Button>
      {
        !loading &&  
        <>
         <Typography variant="h6" textAlign='center' color="#424242" sx={{ mb: 2 }}>
        Ansatte informasjon
        </Typography>
  
        <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 1,
          marginTop: 1,
          marginBottom: 3,
          mx: 1,
          // width: "100%",
          width: "68rem",
        }}
      > <Stack direction="row" spacing={1} sx={{backgroundColor:'#2f4874', p:1, borderRadius:3}}>
         <AccountCircleIcon fontSize="small" sx={{color:'#fff'}}/>
         <Typography  sx={{color:'#fff', fontSize:15}}>{userName}</Typography>
          </Stack>
           <>
           <Button
              className="FilterButton"
              sx={{width:90, height:40}}
             startIcon={<FilterListIcon />}
             ref={anchorRef}
             aria-controls={open ? "composition-menu" : undefined}
             aria-expanded={open ? "true" : undefined}
             aria-haspopup="true"
             onClick={handleToggle}
             
           >
             Dato
           </Button>
           <Popper
             open={open}
             anchorEl={anchorRef.current}
             role={undefined}
             placement="bottom-start"
             transition
             disablePortal
             sx={{ zIndex: "tooltip" }}
           >
             {({ TransitionProps, placement }) => (
               <Grow
                 {...TransitionProps}
                 style={{
                   transformOrigin:
                     placement === "bottom-start" ? "left top" : "left bottom",
                 }}
               >
                 <Paper>
                   <ClickAwayListener onClickAway={handleClose}>
                     <MenuList
                       autoFocusItem={open}
                       id="composition-menu"
                       aria-labelledby="composition-button"
                       onKeyDown={handleListKeyDown}
                     >
                       <DefinedRange
                         ranges={dateState}
                         onChange={handleDateRangeChange}
                         staticRanges={defaultStaticRanges}
                       />
                     </MenuList>
                   </ClickAwayListener>
                 </Paper>
               </Grow>
             )}
           </Popper>
         </>
    </Box>
    </> 
      }
    {loading ? (
      <Grid item xs={12} md={12} sx={{display:'flex', marginTop: 5, ml:3 }}>
        <Typography sx={{ color: "#858585", marginRight: 2 }}>
        Ansatte informasjon laster inn... 
        </Typography>
        <Loading color={"#858585"} size={20} />
      </Grid>
    ) : filteredResults.length > 0 ? filteredDetails : (
      <Typography variant="body2" sx={{ mt: 4, ml: 3, color: "#909090" }}>
        The user has no course
      </Typography>
    )}
  </Grid>
);
};

export default EmployeeResultsContainer;
