import { React, useState, useEffect } from "react";
import {
  Checkbox,
  TextField,
  Autocomplete,
  styled,
  lighten, Grid
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DepartmentUsersByToken } from "../../../api/endpoints";
import apiClient from "../../../api/apiClient";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function DepartmentUserSelect({handleUserSelect, formSubmit, reset}) {
  const [userData, setUserData] = useState([]);
  const [selectedBox, setSelectedBox]= useState([])
  const [formValid, setFormValid] = useState(false);
  const [, setLoading] = useState(false)
  // console.log(userData)

  // ! API call to get departments and workers

  useEffect(() => {
    (async () => {
    setLoading(true)
      try {
        const departmentUsers = await apiClient.get(DepartmentUsersByToken);
        setUserData(departmentUsers.data.data);
      } catch (err) {
        console.log(err);
      }finally{
        setLoading(false)
      }
    })();
  }, []);


   // ! Organise the JSON data


  const options = userData.map(({ name, surname, departmentId, userId}) => ({
    name: `${name} ${surname}`,
    departmentName: `${departmentId}`,
    id : `${userId}`
  }));


  // ! Handle Selected Departments and Users

  const checkOption = (option) =>{
    const check = selectedBox?.some((item)=>item.id === option.id);
    return check
  }

  const checkGroup =(group)=>{
    const groupLength = options.filter((d) => d.departmentName === group).length;
    const selectedGroupLength = selectedBox.filter(
      (d) => d.departmentName === group
    ).length;
    return groupLength === selectedGroupLength;
  };

  const selectGroup = (group)=>{
    const groupedUsers = options.filter((d) => d.departmentName === group);
    const selectedDepartments = selectedBox.filter(
      (d) => d.departmentName === group
    );
    if (selectedDepartments.length > 0) {
      setSelectedBox((prevState) => [
        ...prevState.filter((d) => d.departmentName !== group)
      ]);
    } else {
      setSelectedBox((prevState) => [...prevState, ...groupedUsers]);
    }
  }

  useEffect(() => {
    handleUserSelect(selectedBox)
    setFormValid(selectedBox.length > 0);
  }, [selectedBox]);

  useEffect(() => {
    setSelectedBox([]);
  }, [reset]);


  // ! Style Group Header in Autocomplete

  const GroupHeader = styled("div")(({ theme }) => ({
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  }));

  return (
    <Grid item xs={12}>
    <Autocomplete
      multiple
      fullWidth
      disableCloseOnSelect
      value={selectedBox}
      onChange={(_, option)=>{setSelectedBox([...option]); handleUserSelect(selectedBox)}}
      options={options}
      groupBy={(option) => option.departmentName}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Velg deltaker"
          variant="standard"
          placeholder="Legg deltaker"
          required
          error={formSubmit && !formValid}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={checkOption(option)}
          />
          {option.name}
        </li>
      )}
      renderGroup={(params, index) => (
        <div key={params.group + '-' + index}>
          <GroupHeader >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={checkGroup(params.group)}
              onClick={() =>selectGroup(params.group)}
            />
            {params.group}
          </GroupHeader>
          {/* <> {params.children} </> */}
        </div>
      )}
    />
    </Grid>
  );
}
