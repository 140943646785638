import React, { useState } from "react";
import Header from "../layout/Header";
import AvailableModules from "../components/DashboardComponents/AvailableModules";
import { Grid, Skeleton, Typography } from "@mui/material";
// import Notifications from "../components/DashboardComponents/Notifications";
import MiniCalender from "../components/DashboardComponents/MiniCalender";
// import ModuleSuggestions from "../components/DashboardComponents/ModuleSuggestions/ModuleSuggestions";
import ResultContainer from "../components/DashboardComponents/GeneralResults/ResultContainer";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const loadingState = useSelector((state) => state.loading);
  const date = new Date().toLocaleDateString("nb-NO", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });  

  return (
    <main>
      <Header header={"Velkommen"} headerSize={"body1"} subTitle={date} />
      <Grid container sx={{ width: "84vw", mt: 3, mb: 5, ml: 1 }}>

        {loadingState.resultContainerLoading ? (
          <Grid item xs={12} sm={11} md={11} lg={11} xl={10}>
            <Typography variant="h4">
              <Skeleton width="22%" />
            </Typography>
            <Skeleton variant="rectangular" width="100%" height="200px" />
          </Grid>
        ) : (
          <Grid item xs={12} sm={11} md={11} lg={11} xl={10}>
            <ResultContainer />
          </Grid>
        )}



<Grid item md={12}  sx={{ mt: 7 }}>
          {/* <ModuleSuggestions /> */}
        </Grid>


        {loadingState.miniCalendarLoading ? (
          <Grid item lg={7} xl={6} sx={{ mt: 7 }}>
            <Typography variant="h4">
              <Skeleton width="40%" />
            </Typography>
            <Skeleton variant="rectangular" width="100%" height="250px" />
          </Grid>
        ) : (
          <Grid
            item
            lg={12}
            xl={6.5}
            sx={{ mt: 7}}
          >
            <MiniCalender />
          </Grid>
        )}


{loadingState.availableModulesLoading ? (
          <Grid
            item
            lg={6}
            xl={5}
            sx={{ mt:7, ml:{xl:4}}}
          >
            <Typography variant="h4">
              <Skeleton width="40%" />
            </Typography>
            <Skeleton variant="rectangular" height="200px" />
          </Grid>
        ) : (
          <Grid
            item
            lg={12}
            xl={5}
            sx={{ mt:7 }}
          >
            <AvailableModules />
          </Grid>
        )}



        {/* <Grid
          item
          xs={12}
          md={3.5}
          lg={4}
          xl={4}
          sx={{
            mt: { xs: 5, sm: 5, md: 5, lg: 6 },
            ml: { md: 3, lg: 8, xl: 10 },
          }}
        >
          <Notifications />
        </Grid> */}
      </Grid>
    </main>
  );
};

export default Dashboard;
